<template>
  <div class="examine_detail">
    <back />
    <div class="content globle_border">
      <el-form class="form" :model="ruleForm" label-width="100px" disabled>
        <div class="title1">查看学校信息</div>
        <el-form-item label="学校名称" prop="schoolName">
          <el-input v-model="ruleForm.schoolName"></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="contactMan">
          <el-input v-model="ruleForm.contactMan"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="contactTel">
          <el-input v-model="ruleForm.contactTel"></el-input>
        </el-form-item>
        <el-form-item label="学校地区" prop="selectedOptions">
          <el-input v-model="ruleForm.selectedOptions"></el-input>
        </el-form-item>
        <el-form-item label="学校地址" prop="address">
          <el-input type="textarea" v-model="ruleForm.address" maxlength="100" show-word-limit :autosize="{ minRows: 2, maxRows: 5 }"></el-input>
        </el-form-item>
        <el-form-item label="学校简介">
          <!-- <el-input type="textarea" v-model="ruleForm.remark"></el-input> -->
          <div v-html="ruleForm.remark" class="Runecloth"></div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: null,
      ruleForm: {
        schoolName: "",
        contactMan: "",
        contactTel: "",
        selectedOptions: [],
        address: "",
        remark: "", // 招标公告
      },
    }
  },
  created() {
    this.id = this.$route.query.id
    this.schoolDetail()
  },
  methods: {
    schoolDetail() {
      this.$axios
        .get(this.$api.schoolDetail, {
          params: {
            id: this.id,
          },
        })
        .then(res => {
          this.ruleForm = res.data.result.result
          this.ruleForm.selectedOptions = [this.ruleForm.province, this.ruleForm.city, this.ruleForm.area]
          // this.ruleForm.selectedOptions 数组去除逗号添加/
          this.ruleForm.selectedOptions = this.ruleForm.selectedOptions.join("/")
        })
    },
  },
}
</script>

<style scoped lang="scss">
.examine_detail {
  .content {
    padding: 60px 190px;

    .form {
      .Runecloth {
        margin-left: 50px;
        width: 1000px;
        border-radius: 10px;
        border: 1px solid #e5e7e6;
        padding: 0px 10px;

        ::v-deep img {
          object-fit: contain;
          width: 100%;
        }
      }

      .title1,
      .title2 {
        position: relative;
        font-weight: bold;
        margin-bottom: 40px;
      }

      .title1::before,
      .title2::before {
        content: "";
        position: absolute;
        top: 0;
        left: -12px;
        width: 4px;
        height: 22px;
        background-color: #c27c88;
      }

      .title2 {
        margin-top: 60px;
      }

      ::v-deep .el-form-item__content {
        margin-bottom: 30px;
      }

      ::v-deep .el-input {
        width: 650px;
        margin-left: 50px;
      }

      ::v-deep .el-form-item__error {
        margin-left: 50px;
      }

      ::v-deep .el-form-item__label {
        color: #272727;
        font-size: 16px;
      }

      ::v-deep .el-textarea {
        width: 650px;
        margin-left: 50px;
      }

      ::v-deep .el-input__inner,
      ::v-deep .el-textarea__inner {
        background: #fff;
        border-radius: 10px;
        border: 1px solid #e5e7e6;
      }

      ::v-deep .el-textarea .el-input__count {
        background: transparent !important;
        bottom: -8px;
        font-size: 10px;
      }

      ::v-deep .el-input.is-disabled .el-input__inner,
      ::v-deep .el-textarea.is-disabled .el-textarea__inner {
        color: #1a1a1a;
      }
    }
  }
}
</style>
